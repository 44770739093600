import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';


import { CustomFee } from '@core/models';

import { OfferService, SettingService } from '@core/services';

import * as moment from 'moment';

interface NameValuePair {
  name: string;
  value: string;
}

@Component({
  selector: 'oiq-edit-fee-dialog',
  templateUrl: './edit-fee-dialog.component.html',
  styleUrls: ['./edit-fee-dialog.component.scss']
})
export class EditFeeDialogComponent implements OnInit {
  form: UntypedFormGroup;
  expenseForm: UntypedFormGroup;

  showPaymentPlan = false;

  hideFundedDealOptions = true;

  tenantFinancialSettings: any;

  feeTypes = [];

  dialogOptions = [
    {value: 'FEE'},
    {value: 'EXPENSE'},
  ];
  selectedDialogOption = "FEE"


  constructor(
    private offerService: OfferService,
    private settingsService: SettingService,
    public dialogRef: MatDialogRef<EditFeeDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.getFeeTypes();
    this.getTenantFinancialSettings();

    this.form = new CustomFee();

    this.expenseForm = new UntypedFormGroup({
      name: new UntypedFormControl(''),
      feeDate: new UntypedFormControl(''),
      amount: new UntypedFormControl(0, Validators.required),
      includeInSyndication: new UntypedFormControl(false),
      includeInBalance: new UntypedFormControl(false)
    });
    this.expenseForm.get('includeInSyndication').valueChanges
    .subscribe(value => this.expenseForm.get('includeInBalance').setValue(!value, { emitEvent: false }))

    this.expenseForm.get('includeInBalance').valueChanges
    .subscribe(value => this.expenseForm.get('includeInSyndication').setValue(!value, { emitEvent: false }));

    this.form.get('recurring').valueChanges.subscribe(val => {
      this.showPaymentPlan = val;

      if(this.showPaymentPlan === true) {
        this.addPaymentPlanFormGroup(this.data.dealFunded ? false : true);
        this.form.get('includeInBalance').setValue(null);
        this.form.get('amount').setValidators([Validators.required, Validators.min(0.1)]);
        this.form.get('amount').updateValueAndValidity();
      } else {
        this.form.get('includeInBalance').setValue(false);
        this.form.get('amount').clearValidators();
        this.form.get('amount').updateValueAndValidity();
        this.form.removeControl('paymentPlan');
      }
    });

    if (this.data.dealFunded) {
      this.hideFundedDealOptions = false;
      this.form.get('includeInBalance').setValue(null);
    }
    if (this.data.fee && this.data.fee.id) {
      if (this.data.fee && this.data.fee.feeDate && this.data.fee.feeDate !== '') {
        this.data.fee.feeDate = moment.unix(this.data.fee.feeDate / 1000).utc().format();
      }
      this.form.updateValueAndValidity();

      if (this.data.fee && this.data.fee.type === 'Expense') {
        this.selectedDialogOption = 'EXPENSE'
        this.expenseForm.patchValue(this.data.fee)
      } else {
        this.form.get('recurring').disable();
        this.form.patchValue(this.data.fee);
        this.form.get('includeInBalance').setValue(this.data.fee.includeInBalance); // manually set, patchValue doesn't work for some reason for includeInBalance

        if(this.form.get('recurring').value) {
          this.addPaymentPlanFormGroup();
          this.patchFormData(this.data.fee.paymentPlan);

          if (this.form.get('paymentPlan').value.paymentFrequency === 'Daily') {
            this.form.get('paymentPlan').patchValue({
              paymentDays: {
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true
              }
            });
          } else if (this.form.get('paymentPlan').value.paymentFrequency === 'Weekly' || this.form.get('paymentPlan').value.paymentFrequency === 'Biweekly') {

            const keys = Object.keys(this.form.get('paymentPlan').value.paymentDays).filter(k => this.form.get('paymentPlan').value.paymentDays[k])
            this.form.get('paymentPlan').patchValue({
              paymentDay: keys[0]
            });
          }
        }
      }
    }
  }

  patchFormData(data) {
    this.form.get('paymentPlan').patchValue(data);
  }

  addPaymentPlanFormGroup(app?: boolean) {
    const paymentPlan = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      transmissionMethod: new UntypedFormControl(''),
      transmissionProcessor: new UntypedFormControl(''),
      bankId: new UntypedFormControl(''),
      status: new UntypedFormControl('Active'),
      startDate: new UntypedFormControl(''),
      endDate: new UntypedFormControl(''),
      notes: new UntypedFormArray([]),
      paymentFrequency: new UntypedFormControl('Daily', Validators.required),
      paymentDay: new UntypedFormControl(null),
      paymentDays: this.fb.group({
        monday: new UntypedFormControl(false),
        tuesday: new UntypedFormControl(false),
        wednesday: new UntypedFormControl(false),
        thursday: new UntypedFormControl(false),
        friday: new UntypedFormControl(false)
      })
    });

    if(app) {
      paymentPlan.removeControl('bankId');
    } else {
      paymentPlan.get('startDate').setValidators([Validators.required]);
      paymentPlan.get('transmissionMethod').setValidators([Validators.required]);
    }
    paymentPlan.updateValueAndValidity();
    this.form.addControl('paymentPlan', paymentPlan);
  }

  getFeeTypes() {
    this.settingsService.getFeeTypes()
    .subscribe((r: any) => {
      this.feeTypes = this.transformMapToList(r);
    });
  }

  transformMapToList(map: { [key: string]: string }): NameValuePair[] {
    return Object.entries(map).map(([name, value]) => ({ name, value }));
  }

  checkFeeType(fee) {
    switch (fee) {

      case 'Bounce':
        this.form.get('amount').setValue(this.tenantFinancialSettings.defaultBounceFee);
        this.form.get('name').clearValidators();
        this.form.get('name').updateValueAndValidity();
        break;

      case 'Wire':
        this.form.get('amount').setValue(this.tenantFinancialSettings.defaultWireFee);
        this.form.get('name').clearValidators();
        this.form.get('name').updateValueAndValidity();
        break;

      case 'Other':
        this.form.get('name').setValidators([Validators.required]);
        this.form.get('name').updateValueAndValidity();
        break;

      default:
        this.form.get('name').clearValidators();
        this.form.get('name').updateValueAndValidity();
        break;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  getTenantFinancialSettings() {
    this.settingsService.getTenantFinancialSettings()
    .subscribe((r: any) => {
      this.tenantFinancialSettings = r;
    });
  }

  submit() {
    let data;

    const formValue = this.form.getRawValue();
    const paymentPlanData = formValue.paymentPlan ? formValue.paymentPlan : null;

    if (this.showPaymentPlan === true) {

      if (paymentPlanData.paymentFrequency === 'Daily') {
        paymentPlanData.paymentDays = {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true
        };
      } else if (paymentPlanData.paymentFrequency === 'Monthly') {
        paymentPlanData.paymentDays = {};
      } else {
        paymentPlanData.paymentDays = {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false
        };

        paymentPlanData.paymentDay ? paymentPlanData.paymentDays[paymentPlanData.paymentDay] = true : '';
      }
    }

    if (this.selectedDialogOption === 'FEE') {
      data = this.form.value;
      if (this.showPaymentPlan === true) {
        if (paymentPlanData.transmissionMethod === '') {
          delete paymentPlanData.transmissionMethod;
          delete paymentPlanData.transmissionProcessor;
        }
        data['paymentPlan'] = paymentPlanData;
      }
      data.includeInSyndication = false;

      if (data.type !== 'Other') {
        data.name = data.type.replace('_', ' ') + ' Fee';
      }

    } else if (this.selectedDialogOption === 'EXPENSE') {
      data = this.expenseForm.value;
      data.type = 'Expense';
    }

    let observable;

    if (this.data.fee) {
      if(this.data.fee.recurring) {
        observable = this.offerService.updateRecurringFee(this.data.fundingOffer.id, this.data.fee.id, data);
      } else {
        observable = this.offerService.updateFee(this.data.fundingOffer.id, this.data.fee.id, data);
      }
    } else {
      if (data.paymentPlan) {
        if (data.paymentPlan.startDate === '') {
          delete data.paymentPlan.startDate;
        }
        if (data.paymentPlan.id === null) {
          delete data.paymentPlan.id;
        }
      }
      observable = this.offerService.createFee(this.data.fundingOffer.id, data);
    }

    observable.subscribe((r: any) => {
      this.dialogRef.close(r);
    });
  }

  updateValidator(bool) {
    if (bool) {
      this.form.get('paymentPlan.bankId').setValidators([Validators.required]);
    } else {
      this.form.get('paymentPlan.bankId').clearValidators();
    }
    this.form.get('paymentPlan.bankId').updateValueAndValidity();
  }
}

import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class FundingService extends AbstractService {

  private _funding: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'fundings/';
  }

  get funding(): BehaviorSubject<any> {
    return this._funding;
  }

  clearFunding() {
    this.funding.next(null);
  }

  getById(id: number): Observable<any> {
    return super.getById(id)
      .pipe(map(res => {
        this.funding.next(res);

        return res;
      }));
  }

  reloadFunding(id: number, async: boolean = false): Observable<any> {
    if (!async) {
      this.getById(id).subscribe();
    } else {
      return this.getById(id);
    }
  }

  listByCriteria(ids: string, phoneNumber?: string, email?: string, clientId?: number, clientName?: string, clientLegalName?: string, description?: string, isoManagerIds?: Array<any>, fundingAmountStart?: number, fundingAmountEnd?: number, isoIds?: Array<any>, statuses?: Array<any>, fundingDateFrom?: string, fundingDateTo?: string, onlyInactivePaymentPlans?: boolean, offset?: number, limit?: number, sortBy?: string, sortDir?: string, includeFees: boolean = false): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (ids) { params['ids'] = ids.toString(); }
    if (phoneNumber) { params['phoneNumber'] = phoneNumber.toString(); }
    if (email) { params['email'] = email.toString(); }
    if (clientId) { params['clientId'] = clientId.toString(); }
    if (clientName) { params['clientName'] = clientName; }
    if (clientLegalName) { params['clientLegalName'] = clientLegalName; }
    if (description) { params['description'] = description.toString(); }
    // if (fundingAmountStart) { params['fundingAmountStart'] = fundingAmountStart.toString(); }
    // if (fundingAmountEnd) { params['fundingAmountEnd'] = fundingAmountEnd.toString(); }
    if (isoManagerIds && isoManagerIds.length > 0) { params['isoManagerIds'] = isoManagerIds.join(','); }
    if (isoIds && isoIds.length > 0) { params['isoIds'] = isoIds.join(','); }
    if (statuses && statuses.length > 0) { params['statuses'] = statuses.join(','); }
    if (fundingDateFrom) { params['fundingDateFrom'] = fundingDateFrom; }
    if (fundingDateTo) { params['fundingDateTo'] = fundingDateTo; }
    if (onlyInactivePaymentPlans) { params['onlyInactivePaymentPlans'] = onlyInactivePaymentPlans.toString(); }
    if (includeFees) { params['includeFees'] = includeFees.toString(); }

    return this.http.get(this.getUrl(), { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listDefaulted(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `defaulted`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listDefaultedFilter(ids: string, clientName: string, clientLegalName: string, whiteLabelIds: number[], lawFirmIds: string, lawFirmName: string, phoneNumber: string, email: string, isoIds: Array<any>, startDate: string, endDate: string, fundingDateStart: string, fundingDateEnd: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (ids) { params['ids'] = ids.toString(); }
    if (clientName) { params['clientName'] = clientName; }
    if (clientLegalName) { params['clientLegalName'] = clientLegalName; }
    if (whiteLabelIds) { params['whiteLabelIds'] = whiteLabelIds.join(','); }
    if (lawFirmIds) { params['lawFirmId'] = lawFirmIds; }
    if (lawFirmName) { params['lawFirmName'] = lawFirmName; }
    if (phoneNumber) { params['phoneNumber'] = phoneNumber; }
    if (email) { params['email'] = email; }
    if (isoIds && isoIds.length > 0) { params['isoIds'] = isoIds.join(','); }
    if (startDate) { params['startDate'] = startDate; }
    if (endDate) { params['endDate'] = endDate; }
    if (fundingDateStart) { params['fundingDateStart'] = fundingDateStart; }
    if (fundingDateEnd) { params['fundingDateEnd'] = fundingDateEnd; }

    return this.http.get(this.getUrl() + `defaulted-law-firm-filter`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listPaymentIssues(ids: string, clientName: string, clientLegalName: string, whiteLabelIds: number[], phoneNumber: string, email: string, isoIds: Array<any>, startDate: string, endDate: string, fundingDateStart: string, fundingDateEnd: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (ids) { params['ids'] = ids.toString(); }
    if (clientName) { params['clientName'] = clientName; }
    if (clientLegalName) { params['clientLegalName'] = clientLegalName; }
    if (whiteLabelIds) { params['whiteLabelIds'] = whiteLabelIds.join(','); }
    if (phoneNumber) { params['phoneNumber'] = phoneNumber; }
    if (email) { params['email'] = email; }
    if (isoIds && isoIds.length > 0) { params['isoIds'] = isoIds.join(','); }
    if (startDate) { params['startDate'] = startDate; }
    if (endDate) { params['endDate'] = endDate; }
    if (fundingDateStart) { params['fundingDateStart'] = fundingDateStart; }
    if (fundingDateEnd) { params['fundingDateEnd'] = fundingDateEnd; }

    return this.http.get(this.getUrl() + `payment-issues`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listSlowPay(ids: string, clientName: string, clientLegalName: string, whiteLabelIds: number[], phoneNumber: string, email: string, isoIds: Array<any>, startDate: string, endDate: string, fundingDateStart: string, fundingDateEnd: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (ids) { params['ids'] = ids.toString(); }
    if (clientName) { params['clientName'] = clientName; }
    if (clientLegalName) { params['clientLegalName'] = clientLegalName; }
    if (whiteLabelIds) { params['whiteLabelIds'] = whiteLabelIds.join(','); }
    if (phoneNumber) { params['phoneNumber'] = phoneNumber; }
    if (email) { params['email'] = email; }
    if (isoIds && isoIds.length > 0) { params['isoIds'] = isoIds.join(','); }
    if (startDate) { params['startDate'] = startDate; }
    if (endDate) { params['endDate'] = endDate; }
    if (fundingDateStart) { params['fundingDateStart'] = fundingDateStart; }
    if (fundingDateEnd) { params['fundingDateEnd'] = fundingDateEnd; }

    return this.http.get(this.getUrl() + `slow-pay`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listHealthy(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `healthy`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listOverpaid(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `overpaid`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listPaymentPlans(fundingId: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${fundingId}/payment-plans`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listPayments(fundingId: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${fundingId}/payments`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listSyndications(fundingId: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${fundingId}/syndications`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listCredits(fundingId: number, type: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (type) {
      params['type'] = type;
    }

    return this.http.get(this.getUrl() + `${fundingId}/credits`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listStatuses(fundingId: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${fundingId}/statuses/`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  listWorkflowEvents() {
    return this.http.get(this.getUrl() + `workflow/events`)
      .pipe(map(res => {
        return res;
      }));
  }

  listPaymentWorkflowEvents() {
    return this.http.get(`payments/workflow/events`)
      .pipe(map(res => {
        return res;
      }));
  }

  listSyndicatorWorkflowEvents() {
    return this.http.get(`syndicators/workflow/events`)
      .pipe(map(res => {
        return res;
      }));
  }

  getDefaultedById(id: number) {
    return this.http.get(this.getUrl() + `${id}/defaulted`)
      .pipe(map(res => {
        return res;
      }));
  }

  getPaymentById(fundingId: number, paymentId: number) {
    return this.http.get(this.getUrl() + `${fundingId}/payments/${paymentId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getPaymentPlanById(fundingId: number, paymentPlanId: number) {
    return this.http.get(this.getUrl() + `${fundingId}/payment-plans/${paymentPlanId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getPaymentPlanHistory(paymentPlanId: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    return this.http.get(this.getUrl() + `payment-plan-history/${paymentPlanId}`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  getBalances(id: number) {
    return this.http.get(this.getUrl() + `${id}/balances`)
      .pipe(map(res => {
        return res;
      }));
  }

  getPaymentInformation(id: number) {
    return this.http.get(this.getUrl() + `${id}/payment-information`)
      .pipe(map(res => {
        return res;
      }));
  }

  getPaidInTerm(id: number) {
    return this.http.get(this.getUrl() + `${id}/paid-in-term`)
      .pipe(map(res => {
        return res;
      }));
  }

  getSyndications(id: number) {
    return this.http.get(this.getUrl() + `${id}/syndication-information`)
      .pipe(map(res => {
        return res;
      }));
  }

  getOustandingBalance(id: number) {
    return this.http.get(this.getUrl() + `${id}/outstanding-balance`)
      .pipe(map(res => {
        return res;
      }));
  }

  getOustandingBalanceWithFees(id: number) {
    return this.http.get(this.getUrl() + `${id}/outstanding-balance-with-fees`)
      .pipe(map(res => {
        return res;
      }));
  }

  getPendingBalance(id: number) {
    return this.http.get(this.getUrl() + `${id}/pending-balance`)
      .pipe(map(res => {
        return res;
      }));
  }

  getNotes(id: number, category?: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (category) { params['category'] = category; }

    return this.http.get(this.getUrl() + `${id}/notes`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  createPayment(id: number, data: any) {
    return this.http.post(this.getUrl() + `${id}/payments`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updatePayment(id: number, paymentId: number, data: any) {
    return this.http.put(this.getUrl() + `${id}/payments/${paymentId}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  deletePayment(id: number, paymentId: number) {
    return this.http.delete(this.getUrl() + `${id}/payments/${paymentId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  createPaymentPlan(id: number, data: any) {
    return this.http.post(this.getUrl() + `${id}/payment-plans`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updatePaymentPlan(id: number, paymentPlanId: number, data: any) {
    return this.http.put(this.getUrl() + `${id}/payment-plans/${paymentPlanId}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  deletePaymentPlan(id: number, paymentPlanId: number) {
    return this.http.delete(this.getUrl() + `${id}/payment-plans/${paymentPlanId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  updatePaymentPlanStatus(id: number, paymentPlanId: number, status: string) {
    return this.http.put(this.getUrl() + `${id}/payment-plans/${paymentPlanId}/status/${status}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  updateStatus(id: number, status: string, pausePaymentPlans?: boolean) {
    let url = this.getUrl() + `${id}/status/${status}`;
    if (pausePaymentPlans === true) {
      url += '?pausePaymentPlans=true';
    }
    return this.http.put(url, {})
      .pipe(map(res => {
        return res;
      }));
  }

  sendToLegal(id: number, data: any) {
    return this.http.put(this.getUrl() + `${id}/send-to-legal`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updatePaymentSchedule(id: number, data: any) {
    return this.http.put(this.getUrl() + `${id}/payment-schedule`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  createCredit(id: number, data: any) {
    return this.http.post(this.getUrl() + `${id}/credits`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateCredit(id: number, creditId: number, data: any) {
    return this.http.put(this.getUrl() + `${id}/credits/${creditId}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteCredit(id: number, creditId: number) {
    return this.http.delete(this.getUrl() + `${id}/credits/${creditId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  createFundingSyndication(fundingId: number, data: any) {
    return this.http.post(this.getUrl() + `${fundingId}/syndications/`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  saveNote(id: number, data: any) {
    return this.http.post(this.getUrl() + `${id}/notes`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getBalanceHistory(fundingId: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${fundingId}/balance-history`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getAdminDashboardBalanceDetails(whiteLabelIds?: number[]) {
    const params = {}
    if (whiteLabelIds) { params['whiteLabelIds'] = whiteLabelIds.join(','); }
    return this.http.get(this.getUrl() + `admin-dashboard/balance-details`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getAdminDashboardProcessorDetails(whiteLabelIds?: number[]) {
    const params = {}
    if (whiteLabelIds) { params['whiteLabelIds'] = whiteLabelIds.join(','); }
    return this.http.get(this.getUrl() + `admin-dashboard/processor-details`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getExpectedPayments(offset?: number, limit?: number, sortBy?: string, sortDir?: string, whiteLabelIds?: number[]) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    if (whiteLabelIds) { params['whiteLabelIds'] = whiteLabelIds.join(','); }

    return this.http.get(this.getUrl() + `admin-dashboard/expected-payments`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getFailedPayments(offset?: number, limit?: number, sortBy?: string, sortDir?: string, whiteLabelIds?: number[]) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    if (whiteLabelIds) { params['whiteLabelIds'] = whiteLabelIds.join(','); }

    return this.http.get(this.getUrl() + `admin-dashboard/failed-payments`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  resubmitPaymentsForSameDay(paymentsIds: number[]) {

    return this.http.post(`payments/resubmit-payments-same-day`, paymentsIds)
      .pipe(map(res => {
        return res;
      }));
  }

  getSameDaySubmittedPayments(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `admin-dashboard/same-day-submitted-payments`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getUnsubmittedPayments(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `admin-dashboard/unsubmitted-payments`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getAllUnsubmittedPayments() {
    return this.http.get(this.getUrl() + `admin-dashboard/unsubmitted-payments/all`)
      .pipe(map(res => {
        return res;
      }));
  }

  getPendingPayments(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `admin-dashboard/pending-payments`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getSuccessfulPayments(offset?: number, limit?: number, sortBy?: string, sortDir?: string, whiteLabelIds?: number[]) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    if (whiteLabelIds) { params['whiteLabelIds'] = whiteLabelIds.join(','); }

    return this.http.get(this.getUrl() + `admin-dashboard/successful-payments`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getDefaultBank(id: number) {
    return this.http.get(this.getUrl() + `${id}/banks/default`)
      .pipe(map(res => {
        return res;
      }));
  }

  updateFiledUCC(id: number, filedUCC: boolean) {
    return this.http.put(this.getUrl() + `${id}/ucc/${filedUCC}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  updateWaiveRecurringFees(id: number, waiveRecurringFees: boolean) {
    return this.http.put(this.getUrl() + `${id}/waive-recurring-fees/${waiveRecurringFees}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  addAttachments(fundingId: number, fileIds: number[]) {
    return this.http.put(this.getUrl() + `${fundingId}/attachments`, { attachments: fileIds })
      .pipe(map(res => {
        return res;
      }));
  }

  deleteAttachment(fundingId: number, attachmentId: number) {
    return this.http.delete(this.getUrl() + `${fundingId}/attachments/${attachmentId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  listAttachments(id: number, source: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    if (source) { params['source'] = source; }

    return this.http.get(this.getUrl() + `${id}/attachments`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  getLawFirmDeals(id: number) {
    return this.http.get(this.getUrl() + `law-firms/${id}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getSyndicatorsWithUsers(fundingId: string) {
    return this.http.get(this.getUrl() + `/${fundingId}/syndicator-users`)
      .pipe(map(res => {
        return res;
      }));
  }

  skipPaymentIssues(fundingId: number, data: any) {
    return this.http.put(this.getUrl() + `${fundingId}/keep-status-healthy`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  public getWorkflowEventsTemplateNames(): Observable<any> {
    return this.http.get<any>(this.getUrl() + `workflow/events/template-names`);
  }

  getPaymentWorkflowEventsTemplateNames(): Observable<any> {
    return this.http.get<any>(`payments/workflow/events/template-names`);
  }

  getSyndicatorWorkflowEventsTemplateNames(): Observable<any> {
    return this.http.get<any>(`syndicators/workflow/events/template-names`);
  }

  getFundingsForEditing(id: number) {
    return this.http.get(this.getUrl() + `${id}/edit-funding`)
      .pipe(map(res => {
        return res;
      }));
  }

  editFunding(id: number, data: any) {
    return this.http.put(this.getUrl() + `${id}/edit-funding`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteFunding(id: number) {
    return this.http.put(this.getUrl() + `${id}/delete-funding`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  getFundingHistory(id: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    return this.http.get(this.getUrl() + `${id}/post-fund-changes-history`, { params })
  }

  getFundingHistoryAndCurrentFunding(fundingId, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    return forkJoin([this.getFundingHistory(fundingId, offset, limit, sortBy, sortDir), this.getFundingsForEditing(fundingId)])
  }
}

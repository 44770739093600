import { Component, OnInit, Input, ViewChild, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OfferService, ApplicationService, SettingService } from '@core/services';
import { PrepaymentDialogComponent } from 'src/app/user/applications/view/underwriting/prepayment/prepayment-dialog.component';
import { EditFeeDialogComponent } from '@shared/edit-fee-dialog/edit-fee-dialog.component';
import { trigger, state, style } from '@angular/animations';
import { ConfirmDialogComponent } from '@shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'oiq-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' }))
    ])
  ]
})
export class OffersListComponent implements OnInit {

  @Input() offers: Array<any>;

  @Input() application: any;

  @Input() disabled: boolean;

  @Input() isShowingActions = true;

  @Input() isShowingIncludeOffer = true;


  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Output() onEditOffer: EventEmitter<any> = new EventEmitter<any>();

  @Output() onDeleteOffer: EventEmitter<any> = new EventEmitter<any>();

  @Output() onUpdatedOffer: EventEmitter<any> = new EventEmitter<any>();

  offersTable: any;

  expandedOffersRow: any;

  offerUpdateError: string;

  prepayments: any[] = [];
  maxPrepays: number = 8;
  prepaysCount: number;

  displayedOffersColumns: string[] = [
    'prepayTerms',
    'includeOffer',
    'status',
    'sentOn',
    'fundedAmount',
    'paybackAmount',
    'transmissionMethod',
    'splitPercent',
    'termLength',
    'buyRate',
    'position',
    'commission',
    'commissionPercent',
    'factorRate',
    'bankFeeAmount',
    'totalPayments',
    'paymentAmount',
    'paymentFrequency',
    'percentOfGross',
    'actions'
  ];

  customCommissionPercent: boolean;

  constructor(
    public dialog: MatDialog,
    private offerService: OfferService,
    private applicationService: ApplicationService,
    private settingsService: SettingService
  ) { }

  ngOnInit() {

    this.settingsService.getGeneralTenantSettings().subscribe((res: any) => {
      this.customCommissionPercent = res.features.CustomSyndicationCommission
      if(this.customCommissionPercent && this.application.appliedFundingOffer?.maximumCommissionPercent) {
        //adding maximumCommissionPercent to correct position in table
        const index = this.displayedOffersColumns.indexOf('commissionPercent');
        if (index !== -1) {
          this.displayedOffersColumns.splice(index + 1, 0, 'maximumCommissionPercent');
        }
      } else {
        //removing
        if(this.displayedOffersColumns.includes('maximumCommissionPercent')) {
          this.displayedOffersColumns.filter(column => column != 'maximumCommissionPercent')
        }
      }
    })

    if (!this.isShowingActions) {
      this.displayedOffersColumns = this.displayedOffersColumns.filter(column => column !== 'actions')
    }

    if (!this.isShowingIncludeOffer) {
      this.displayedOffersColumns = this.displayedOffersColumns.filter(column => column !== 'includeOffer')
    }
    this.offersTable = new MatTableDataSource(this.offers);
    this.offersTable.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.offersTable = new MatTableDataSource(this.offers);
  }

  editOffer(row) {
    this.onEditOffer.emit(row);
  }

  deleteOffer(row) {
    this.onDeleteOffer.emit(row);
  }

  reloadOffers(bool) {
    this.onUpdatedOffer.emit(bool);
  }

  toggleOffer(event, id) {
    const data = { sendOffer: event.target.checked };
    this.offerService.update(id, data)
      .subscribe((r: any) => { },
        (error) => {
          this.offerUpdateError = error;
          setTimeout(() => {
            this.offerUpdateError = '';
          }, 2000);
          this.reloadOffers(true);
        });
  }

  invokeTermAction(row, action, event, index) {

    switch (action) {
      case 'addPrepay':

        this.openPrepaymentDialog(row, index);
        break;

      case 'addFee':

        this.openEditFeeDialog(row);
        break;

      case 'applyTerm':

        this.applyOffer(row);
        break;

      case 'edit':

        this.editOffer(row);
        break;

      case 'delete':

        this.deleteOffer(row);
        break;

      default:
        break;
    }
    event.source.value = '';
  }

  applyOffer(row): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '700px',
      data: 'Apply this offer to the funding application?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.offerService.applyOffer(row.id)
          .subscribe((r: any) => {
            this.applicationService.reloadApplication(this.application.id);
          });
      }
    });
  }

  openPrepaymentDialog(row, index?) {
    const dialog = this.dialog.open(PrepaymentDialogComponent, {
      width: '800px',
      data: {
        prepayment: this.application.id,
        prepayments: this.prepayments,
        index: index
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.offerService.createPrepayment(row.id, result)
          .subscribe((r: any) => {
            this.getPrepayments(row.id);
          });
      }
    });
  }

  editPrepaymentDialog(row, index) {
    const dialog = this.dialog.open(PrepaymentDialogComponent, {
      width: '800px',
      data: {
        prepayment: this.prepayments[index],
        prepayments: this.prepayments,
        index: index,
        offer: row
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        result['id'] = this.prepayments[index].id;
        this.offerService.updatePrepayment(result.id, row.id, result)
          .subscribe((r: any) => {
            this.getPrepayments(row.id);
          });
      }
    });
  }

  deletePrepayment(prepay) {
    prepay.deleting = true;
  }

  confirmDeletePrepayment(offer, prepay) {
    this.offerService.deletePrepayment(prepay.id, offer.id)
      .subscribe((r: any) => {
        prepay.deleting = false;

        this.getPrepayments(offer.id);
      });
  }

  cancelDeletePrepayment(prepay) {
    prepay.deleting = false;
  }

  openEditFeeDialog(offer: any, feeId?: number, feeIndex?: number, recurring?: boolean, paymentPlan?: any): void {
    const dialogConfig = new MatDialogConfig();
    if (recurring) {
      dialogConfig.data = {
        fundingOffer: offer,
        application: this.application,
        dealFunded: false,
        paymentPlan: paymentPlan
      };
    } else {
      dialogConfig.data = {
        fundingOffer: offer,
        application: this.application,
        dealFunded: false
      };
    }

    dialogConfig.width = '700px';

    if (feeId) {
      let observable;
      if (!recurring) {
        observable = this.offerService.getFeeById(offer.id, feeId);
      } else {
        observable = this.offerService.getRecurringFeeById(offer.id, feeId);
      }
      observable.subscribe((data) => {
        dialogConfig.data['fee'] = data;

        const dialogRef = this.dialog.open(EditFeeDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            offer.fees[feeIndex] = result;
          }
        });
      });
    } else {
      const dialogRef = this.dialog.open(EditFeeDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          offer.fees.push(result);
        }
      });
    }
  }

  showBankFee(fees) {
    let feeAmount = 0;
    fees.find(fee => {
      if (fee.type === 'Origination') {
        feeAmount += fee.amount;
      }
    });
    return feeAmount;
  }

  deleteFee(fee) {
    fee.deleting = true;
  }

  confirmDeleteFee(offer: any, fee: any, feeIndex: number, recurring?: boolean) {
    let observable;
    if (!recurring) {
      observable = this.offerService.deleteFee(offer.id, fee.id);
    } else {
      observable = this.offerService.deleteRecurringFee(offer.id, fee.id);
    }

    observable.subscribe((r: any) => {
      offer.fees.splice(feeIndex, 1);
      fee.deleting = false;
    });
  }

  cancelDeleteFee(fee) {
    fee.deleting = false;
  }

  toggleOffersRow(row) {
    this.expandedOffersRow = this.expandedOffersRow === row ? null : row;

    if (this.expandedOffersRow) {
      this.getPrepayments(row.id);
    }
  }

  getPrepayments(id) {
    this.offerService.listPrepayments(id)
      .subscribe((r: any) => {
        this.prepayments = r.content;
        this.prepaysCount = this.prepayments.length;
      });
  }

  checkPrepaysLImit(row) {
    // ensure that adding is disabled and the prepayments are loaded before checking the limit
    if (!this.prepaysCount) {
      this.prepaysCount = 8;
      this.getPrepayments(row.id);
    }
  }

  getMaximumCommissionValue(row): number {
    return row.maximumCommissionPercent ? row.maximumCommissionPercent : row.commissionPercent;
  }

}

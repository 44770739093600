<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>{{ data.fee ? 'Edit ' : 'Add '}} {{selectedDialogOption}}</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>

  <div mat-dialog-content class="pt-0">
    <div class="row">
      <div class="col-6 form-group">
        <form>
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="selectedDialogOption" name="feeOrExpense" [disabled]="this.data.fee">
              <mat-option *ngFor="let option of dialogOptions" [value]="option.value">
                {{option.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>

    <ng-container *ngIf="selectedDialogOption === 'FEE'">
      <form [formGroup]="form" autocomplete="off">
        <div class="row">
          <div class="form-group col-sm-6">
            <mat-label>Type *</mat-label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="type" required (selectionChange)="checkFeeType($event.value)">
                <mat-option *ngFor="let row of feeTypes" [value]="row.value">
                  {{ row.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="!hideFundedDealOptions" class="form-group col-sm-6">
            <mat-label>Fee Date</mat-label>
            <mat-form-field appearance="outline">
              <input matInput [matDatepicker]="feeDate" placeholder="01 / 01 / 2020" formControlName="feeDate" oiqValidateDate>
              <mat-datepicker-toggle matPrefix [for]="feeDate"></mat-datepicker-toggle>
              <mat-datepicker #feeDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="row" *ngIf="form.value.type === 'Other'">
          <div class="form-group col-sm-6">
            <mat-label>Name</mat-label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="name" type="text">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-6">
            <mat-label>Amount</mat-label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="amount" type="text" currencyMask>
            </mat-form-field>
          </div>

          <div *ngIf="selectedDialogOption === 'FEE'" class="col-sm-6">
            <mat-checkbox color="primary" formControlName="recurring">Recurring</mat-checkbox>
          </div>

          <div *ngIf="hideFundedDealOptions && !showPaymentPlan" class="form-group col-sm-6">
            <mat-radio-group formControlName="includeInBalance">
              <mat-radio-button [value]="true">Add to balance</mat-radio-button><br>
              <mat-radio-button [value]="false">Deduct at funding</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div *ngIf="showPaymentPlan">
          <oiq-payment-plan [recurringFee]="true" [data]="data" (formChanged)="patchFormData($event)">
          </oiq-payment-plan>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="selectedDialogOption === 'EXPENSE'">
      <form [formGroup]="expenseForm" autocomplete="off">
        <div class="row form-group">

          <div class="col-sm-6">
            <mat-checkbox color="primary" formControlName="includeInSyndication">Include Expense In Syndication
            </mat-checkbox>
          </div>
          <div class="col-sm-6">
            <mat-checkbox color="primary" formControlName="includeInBalance">Include Expense In Balance</mat-checkbox>
          </div>
        </div>
        <div class="row form-group">
          <div class="form-group col-sm-6">
            <mat-label>Description</mat-label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="name" type="text" required>
            </mat-form-field>
          </div>
        </div>

        <div class="row form-group ">
          <div class="col-sm-6">
            <mat-label>Amount</mat-label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="amount" type="text" currencyMask required>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-label>Expense Date</mat-label>
            <mat-form-field appearance="outline">
              <input matInput [matDatepicker]="feeDate" placeholder="01 / 01 / 2020" formControlName="feeDate" oiqValidateDate>
              <mat-datepicker-toggle matPrefix [for]="feeDate"></mat-datepicker-toggle>
              <mat-datepicker #feeDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </form>
    </ng-container>

  </div>
  <div mat-dialog-actions *ngIf="selectedDialogOption === 'FEE'">
    <button mat-stroked-button (click)="cancel()" cdkFocusInitial oiq-loading [spinner]="false" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="accent" [buttonDisabled]="!this.form.valid" (click)="submit()" oiq-loading>
      Submit
    </button>
  </div>
  <div mat-dialog-actions *ngIf="selectedDialogOption === 'EXPENSE'">
    <button mat-stroked-button (click)="cancel()" cdkFocusInitial oiq-loading [spinner]="false">Cancel</button>
    <button mat-raised-button color="accent" [buttonDisabled]="!this.expenseForm.valid" (click)="submit()" oiq-loading>
      Submit
    </button>
  </div>
</div>

<div class="mat-dialog-title-wrapper">
  <h1 mat-dialog-title>Add Syndication Offer</h1>
  <button mat-button class="close-dialog" mat-dialog-close>
    <i class="material-icons">clear</i>
  </button>
</div>
<div mat-dialog-content>
  <ng-container *ngIf="data">
    <oiq-add-syndication-offers
      [parentForm]="form"
      [syndicationOffers]="data?.syndicationOffers"
      [fundedAmount]="data?.application?.appliedFundingOffer?.fundedAmount"
      [disbursementAmount]="data?.funding?.disbursementAmount ? data?.funding?.disbursementAmount : data.application?.appliedFundingOffer?.disbursementAmount"
      [factorRate]="data.application?.appliedFundingOffer?.factorRate"
      [commissionPercent]="getCommissionPercent()"
      [maximumCommissionPercent]="this.data.application?.appliedFundingOffer?.maximumCommissionPercent"
      [paybackAmount]="data.application?.appliedFundingOffer?.paybackAmount"
      [whiteLabelId]="data.application?.whiteLabelId"
      [customCommissionPercentEnabled]="this.data.customCommissionPercentEnabled"
      >
    </oiq-add-syndication-offers>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="accent" type="submit"  (click)="save()" [buttonDisabled]="!form.valid" oiq-loading cdkFocusInitial>
    Send
  </button>
</div>

import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators, AbstractControl } from '@angular/forms';
import { Address } from "@core/models/address";
import { PaymentDays } from './contract';

export class GeneralSettings extends UntypedFormGroup {
  constructor() {
    super({
      allowFundingPaymentSelectionOnFinalApproval: new UntypedFormControl(false),
      statusSyndicationCanBeginAt: new UntypedFormControl('Offer_Applied'),
      eSignatureProvider: new UntypedFormControl(),
      minimumPasswordLength: new UntypedFormControl('', Validators.compose([Validators.min(8), Validators.required])),
      maximumPasswordLength: new UntypedFormControl('', Validators.compose([Validators.min(16), Validators.max(50), Validators.required])),
      useGlobalESignatureProviderSettings: new UntypedFormControl(true),
      emailFromAddress: new UntypedFormControl({ value: '', validators: Validators.compose([Validators.email]), updateOn: 'blur' }),
      contractsEmailAddress: new UntypedFormControl({ value: '', validators: Validators.compose([Validators.email]), updateOn: 'blur' }),
      renewalsEmailAddress: new UntypedFormControl({ value: '', validators: Validators.compose([Validators.email]), updateOn: 'blur' }),
      emailPhoneNumber: new UntypedFormControl(),
      useDefaultMasterEmailNotificationTemplate: new UntypedFormControl(true),
      masterEmailNotificationTemplate: new UntypedFormControl(),
      whiteLabelNotificationSettings: new UntypedFormControl(),
      emailAddressRecipients: new UntypedFormArray([]),
      paymentReturnedRecipients: new UntypedFormArray([]),
      missingAutomatedPaymentEventRecipients: new UntypedFormArray([]),
      missingManualPaymentEventRecipients: new UntypedFormArray([]),
      memoField: new UntypedFormControl(),
      processSetting: new ProcessSetting(),
      docuSignIntegratorKey: new UntypedFormControl(''),
      docuSignUserId: new UntypedFormControl(''),
      docuSignPrivateKey: new UntypedFormControl(''),
      helloSignApiKey: new UntypedFormControl(''),
      decisionLogicServiceKey: new UntypedFormControl(''),
      decisionLogicSiteUserGuid: new UntypedFormControl(''),
      decisionLogicProfileId: new UntypedFormControl(''),
      clearAccountNumber: new UntypedFormControl(''),
      clearUserId: new UntypedFormControl(''),
      clearPassword: new UntypedFormControl(''),
      crsUsername: new UntypedFormControl(''),
      crsRiskModel: new UntypedFormControl(''),
      crsPassword: new UntypedFormControl(''),
      plaidClientId: new UntypedFormControl(''),
      plaidSecret: new UntypedFormControl(''),
      plaidProducts: new UntypedFormArray([]),
      workflowBankInsightProviderUsed: new UntypedFormControl('DecisionLogic'),
      fundingOnly: new UntypedFormControl(false),
      mfaEnforced: new UntypedFormControl(false),
      features: new TenantFeatures(),
      moneyThumbTierPricings: new UntypedFormArray([]),
      moneyThumbThumbprintTierPricings: new UntypedFormArray([]),
      moneyThumbColumns: new UntypedFormArray([]),
      creditCheckProvider: new UntypedFormControl(''),
      excludeFromCreditCheck: new UntypedFormControl(true),
      maximumContractOwners: new UntypedFormControl(2),
      passwordExpiresInDays: new UntypedFormControl(null),
      passwordNeverExpires: new UntypedFormControl(true),
      passwordSettings: new UntypedFormArray([]),
      applicationTypes: new UntypedFormArray([])
    });
  }
}

export class ProcessSetting extends UntypedFormGroup {
  constructor() {
    super({
      includeUnderwritingReview: new UntypedFormControl()
    });
  }
}

export class DaysInMonthGroup extends UntypedFormGroup {
  constructor() {
    super({
      effectiveDate: new UntypedFormControl('', Validators.required),
      numberOfDays: new UntypedFormControl('', [Validators.required, Validators.max(31), Validators.min(1)]),
    })
  }
}

export class FinancialSettings extends UntypedFormGroup {
  constructor() {
    super({
      daysInMonth: new UntypedFormControl('', [Validators.required, Validators.max(31), Validators.min(20)]),
      daysInMonthList: new UntypedFormArray([]),
      weeksInMonth: new UntypedFormControl('', [Validators.required, Validators.max(5), Validators.min(3)]),
      defaultBankFeePercents: new UntypedFormArray([]),
      defaultTermLength: new UntypedFormControl(''),
      defaultBounceFee: new UntypedFormControl(''),
      defaultStopPaymentFee: new UntypedFormControl(''),
      defaultFrozenAccountFee: new UntypedFormControl(''),
      defaultBuyRate: new UntypedFormControl(''),
      defaultDefaultFee: new UntypedFormControl(''),
      defaultAccountClosedFee: new UntypedFormControl(''),
      defaultCommission: new UntypedFormControl(''),
      defaultPaymentFrequency: new UntypedFormControl(''),
      minimumRenewalPercent: new UntypedFormControl(''),
      defaultBankFeePercent: new UntypedFormControl(''),
      defaultWireFee: new UntypedFormControl(''),
      defaultPrepayBuyRates: new UntypedFormArray([]),
      defaultCustomFees: new UntypedFormArray([]),
      syndicatorMinimumFundedAmount: new UntypedFormControl(''),
      defaultPrepays: new UntypedFormArray([]),
      createHolidayMakeUpPaymentAutomatically: new UntypedFormControl(false),
    });
  }
}

export class DefaultPrepayBuyRate extends UntypedFormGroup {
  constructor() {
    super({
      value: new UntypedFormControl(0, Validators.required)
    });
  }
}

export class DefaultPrepays extends UntypedFormGroup {
  constructor() {
    super({
      buyRate: new UntypedFormControl('', Validators.compose([Validators.required, Validators.min(1.00), buyRateLessThanFactorRateValidator, factorRateGreaterThanBuyRateValidator])),
      startDay: new UntypedFormControl(''),
      endDay: new UntypedFormControl(''),
      factorRate: new UntypedFormControl('', Validators.compose([Validators.min(1.00), buyRateLessThanFactorRateValidator, factorRateGreaterThanBuyRateValidator]))
    });
  }
}

export class OCRTier extends UntypedFormGroup {
  constructor() {
    super({
      start: new UntypedFormControl(null),
      end: new UntypedFormControl(null),
      price: new UntypedFormControl(null)
    });
  }
}

export class CustomFee extends UntypedFormGroup {
  constructor() {
    super({
      type: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl(''),
      feeDate: new UntypedFormControl(''),
      recurring: new UntypedFormControl(false),
      includeInBalance: new UntypedFormControl(false),
      amount: new UntypedFormControl(0)
    });
  }
}

export class DefaultFee extends UntypedFormGroup {
  constructor() {
    super({
      type: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl(''),
      feeDate: new UntypedFormControl(''),
      recurring: new UntypedFormControl(false),
      includeInBalance: new UntypedFormControl(true),
      amount: new UntypedFormControl(0),
      paymentFrequency: new UntypedFormControl(''),
      paymentDays: new PaymentDays(),
      paymentDay: new UntypedFormControl('')
    });
  }
}

export class DefaultBankFeePercent extends UntypedFormGroup {
  constructor() {
    super({
      position: new UntypedFormControl('', Validators.required),
      bankFeePercent: new UntypedFormControl(0, Validators.required),
      fundedAmount: new UntypedFormControl(0, Validators.required)
    });
  }
}

export class TenantSyndicatorSettings extends UntypedFormGroup {
  constructor() {
    super({
      automaticAch: new UntypedFormControl(false, Validators.required),
      paymentFrequency: new UntypedFormControl('Weekly', Validators.required),
      paymentDay: new UntypedFormControl('Monday', Validators.required),
      defaultManagementFeePercent: new UntypedFormControl(0),
      emailAddressRecipients: new UntypedFormArray([])
    });
  }
}

export class TenantFeatures extends UntypedFormGroup {
  constructor() {
    super({
      BorrowingBase: new UntypedFormControl(false),
      Clear: new UntypedFormControl(false),
      DecisionLogic: new UntypedFormControl(false),
      Experian: new UntypedFormControl(false),
      MoneyThumb: new UntypedFormControl(false),
      Crs: new UntypedFormControl(false),
      Plaid: new UntypedFormControl(false),
      PublicApi: new UntypedFormControl(false),
      OfferRange: new UntypedFormControl(false),
      SyndicatorWallet: new UntypedFormControl(false),
      PreUnderwriting: new UntypedFormControl(false),
      CustomSyndicationCommission: new UntypedFormControl(false),
      BrokerPortal: new UntypedFormControl(false),
      CustomCommission: new UntypedFormControl(false)
    });
  }
}

export class WhiteLabelData extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null, Validators.required),
      address: new Address(),
      email: new UntypedFormControl(null, Validators.compose([Validators.email, Validators.required])),
      memoField: new UntypedFormControl(null, Validators.required),
      emailLogoId: new UntypedFormControl(null),
      logoId: new UntypedFormControl(null),
      tenantId: new UntypedFormControl(null),
      tenantDefault: new UntypedFormControl(false),
      businessName: new UntypedFormControl(null),
      dbaName: new UntypedFormControl(null),
      phoneNumber: new UntypedFormControl(null)
    })
  }
}

export class WhiteLabelSettings extends UntypedFormGroup {
  constructor() {
    super({
      docuSignIntegratorKey: new UntypedFormControl(null),
      docuSignPrivateKey: new UntypedFormControl(null),
      docuSignUserId: new UntypedFormControl(null),
      emailFromAddress: new UntypedFormControl(null, Validators.compose([Validators.email, Validators.required])),
      contractsEmailAddress: new UntypedFormControl(null, Validators.compose([Validators.email, Validators.required])),
      renewalsEmailAddress: new UntypedFormControl(null, Validators.compose([Validators.email, Validators.required])),
      overrideDefaultSettingsWithWhiteLabelSettings: new UntypedFormControl(false),
      masterEmailNotificationTemplate: new UntypedFormControl(null),
      tenantId: new UntypedFormControl(null),
      useGlobalESignatureProviderSettings: new UntypedFormControl(true),
      whiteLabelId: new UntypedFormControl(null),
      bankInfo: new UntypedFormGroup({
        accountNumber: new UntypedFormControl(null, Validators.required),
        accountType: new UntypedFormControl(null, Validators.required),
        accountName: new UntypedFormControl(null, Validators.required),
        address: new UntypedFormControl(null),
        branch: new UntypedFormControl(null),
        dda: new UntypedFormControl(null),
        name: new UntypedFormControl(null, Validators.required),
        routingNumber: new UntypedFormControl(null, Validators.required),
      })
    })
  }
}

export class IntegrationsData extends UntypedFormGroup {
  constructor() {
    super({
      decisionLogicProfileId: new UntypedFormControl(""),
      decisionLogicServiceKey: new UntypedFormControl(""),
      decisionLogicSiteUserGuid: new UntypedFormControl(""),
      clearAccountNumber: new UntypedFormControl(""),
      clearPassword: new UntypedFormControl(""),
      clearUserId: new UntypedFormControl(""),
      creditCheckProvider: new UntypedFormControl(null),
      crsRiskModel: new UntypedFormControl(null),
      crsUsername: new UntypedFormControl(""),
      crsPassword: new UntypedFormControl(""),
      plaidClientId: new UntypedFormControl(""),
      plaidSecret: new UntypedFormControl(""),
      plaidProducts: new UntypedFormArray([]),
      globalHoldingsUsername: new UntypedFormControl(""),
      globalHoldingsPassword: new UntypedFormControl(""),
      moneyThumbTierPricings: new UntypedFormArray([]),
      workflowBankInsightProviderUsed: new UntypedFormControl(null),
    })
  }
}

export class BBSettings extends UntypedFormGroup {
  constructor() {
    super({
      fundingDateStart: new UntypedFormControl(''),
      originationDate: new UntypedFormControl(''),
      maxTermLength: new UntypedFormControl(''),
      unpaidPrincipalLimit: new UntypedFormControl(''),
      minFactorRate: new UntypedFormControl(''),
      maxPercentBehind: new UntypedFormControl(''),
      dailyMissedLimit1: new UntypedFormControl(''),
      weeklyMissedLimit1: new UntypedFormControl(''),
      dailyMissedLimit2: new UntypedFormControl(''),
      weeklyMissedLimit2: new UntypedFormControl(''),
      levelOnePercent: new UntypedFormControl(''),
      levelTwoPercent: new UntypedFormControl(''),
      levelThreePercent: new UntypedFormControl(''),
      minFico: new UntypedFormControl(null),
      maxPosition: new UntypedFormControl(null),
      weeksInYear: new UntypedFormControl(''),
      daysInYear: new UntypedFormControl(''),
      whiteLabels: new UntypedFormControl(''),
    })
  }
}

export class ApplicationType extends UntypedFormGroup {
  constructor() {
    super({
      type: new UntypedFormControl(""),
      isNative: new UntypedFormControl(false),
    })
  }
}

function buyRateLessThanFactorRateValidator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }
  const factorRate = formControl.parent.get('factorRate').value;
  const buyRate = formControl.parent.get('buyRate').value;
  if (factorRate && buyRate) {
    if (buyRate > factorRate) {
      return { buyRateGreaterThanFactorRateError: true };
    }
    formControl.parent.get('factorRate').setErrors(null);
    return null;
  }
  return null;
}

function factorRateGreaterThanBuyRateValidator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }
  const factorRate = formControl.parent.get('factorRate').value;
  const buyRate = formControl.parent.get('buyRate').value;
  if (factorRate && buyRate) {
    if (factorRate < buyRate) {
      return { factorRateLessThanBuyRateError: true };
    }
    formControl.parent.get('buyRate').setErrors(null);
    return null;
  }
  return null;
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CookieService } from 'ngx-cookie-service';

import { EditLookupListDialogComponent } from './edit-lookup-list-dialog/edit-lookup-list-dialog.component';

import { LookupListService, TenantService } from '@core/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '@core/authentication/auth.service';
import { mapDefaultApplicationType } from '@shared/application-type-mapper.util';

const APPLICATION_TYPE_TAB = 'Application Types';

@Component({
  selector: 'oiq-lookup-lists',
  templateUrl: './lookup-lists.component.html',
  styleUrls: ['./lookup-lists.component.scss']
})

export class LookupListsComponent implements OnInit, OnDestroy {

  types: Array<string> = [];

  rows: Array<any> = [];
  datasource: any = new MatTableDataSource([]);
  total: number;

  selection = new SelectionModel(true, []);

  activeTab: string = 'email';

  columns: string[] = ['value', 'actions'];

  private tenantId: number;
  private destroy$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private lookupListService: LookupListService,
    private authService: AuthService,
    private tenantService: TenantService,
    private cookieService: CookieService,
  ) { }

  ngOnInit() {
    this.tenantId = JSON.parse(this.cookieService.get('currentUser')).tenantId;
    this.getLookupListTypes();
  }


  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.datasource.data.filter(row => row.tenantId).length;

    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.datasource.data.filter(row => row.tenantId).forEach(row => this.selection.select(row));
  }

  editLookupList(row?: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      type: this.activeTab,
      lookupList: row,
      tenantId: this.tenantId
    };
    dialogConfig.width = '1000px';
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(EditLookupListDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchLookupData(this.activeTab);
      }
    });
  }

  deleteRow(row) {
    row.deleting = true;
  }

  confirmDelete(row) {
    if (this.activeTab === APPLICATION_TYPE_TAB) {
      this.tenantService.removeApplicationType(this.tenantId, row.type)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.getTenantApplicationTypes();
        });
    } else {
      this.lookupListService.delete(row.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((r: any) => {
          row.deleting = false;
          this.getLookupListByType(this.activeTab);
        });
    }

  }

  cancelDelete(row) {
    row.deleting = false;
  }

  getTenantApplicationTypes() {
    this.tenantService.getApplicationTypes(this.tenantId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.total = response.length;
        this.datasource = new MatTableDataSource(response.map((appType) => ({ ...appType, value: mapDefaultApplicationType(appType.type, appType.isNative), removable: !appType.isNative, })));
      })
  }

  getLookupListTypes() {
    this.lookupListService.listTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe((r: any) => {
        this.types = r;
        if (!this.authService.hasRole('SUPER_ADMIN')) {
          this.types.push('Application Types');
        }
        this.selectTab(this.types[0]);
      });
  }

  getLookupListByType(type: string) {
    this.lookupListService.listByType(type)
      .pipe(takeUntil(this.destroy$))
      .subscribe((r: any) => {
        this.total = r.totalElements;
        this.datasource = new MatTableDataSource(r.content.map((row) => ({ ...row, removable: true })));
      });
  }

  selectTab(tab: string) {
    this.activeTab = tab;
    this.fetchLookupData(tab);
  }

  fetchLookupData(tab: string) {
    if (tab === APPLICATION_TYPE_TAB) {
      this.getTenantApplicationTypes();
    } else {
      this.getLookupListByType(this.activeTab);
    }
  }
}
